import React  from 'react'
//import { Link } from 'gatsby'
import { FaFacebookSquare, FaInstagram, FaMailBulk, FaStar } from 'react-icons/fa';
 
// Styles
import { FooterWrapper, FooterWrapper2, FooterBottom } from "./Footer.styles"

//Hooks

// {footerQuery.tjanst.map(item => (
//     <Link to={item.link} key={item.id}>
//         {item.name}
//     </Link>
// )) }

// import { useFooterMetaQuery } from 'Hooks/useFooterQuery'

const Footer = () => { 
    // const footerQuery = useFooterMetaQuery()
    return(
    <FooterWrapper>
        <div className="footer-top">
            <p>Om du vill ansluta ditt företag måste du ha yrkestrafiktillstånd 
            och jobbat inom flyttbranchen i minst 8 år. 
            Du är välkommen att kontakta oss via vårt formulär eller mejla oss på 
            <a href="mailto:info@flyttofferten.se"> info@flyttofferten.se. </a>
            Sedan 2009 har 31 900 personer tagit in offerter för flytthjälp här.</p>
        
        </div>
        <div className="footer-bottom" id="kontakt">
            <div className="links">
                <h5>Kontakt</h5>
                <p>Lilla Bommen 1<br />411 04 Göteborg</p>
                <p>Läppstiftet i Göteborg <br/>vån 8 Flyttofferten</p>
                <a href="tel:0722955870"> 0722-95 58 70</a>
                <a href="mailto:info@flyttofferten.se"> info@flyttofferten.se</a><br/>
                <a href="https://www.facebook.com/flyttofferten.se" target="_blank" >Följ oss på Facebook</a>
                
            </div>
            <div className="kontakt">
            <h5>Öppettider</h5>
                <p>
                Mån-fre 07.00-19.00<br/>
                Lör-sön 10.00-16.00
                </p>
                
            </div>
            {/* <div className="tider">
                <h5>Address</h5>
                <p>
                
                </p>
            </div> */}
        </div>
    </FooterWrapper>
    
    )
    
}

export default Footer

